.form {
	display: flex;
	flex-direction: column;
	width: 18rem;
}

.fields {
	margin-bottom: 1.5rem;

	& > div:not(:last-child) {
		margin-bottom: .5rem;
	}
}

.preloaderWrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}
