@mixin headline-base {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  white-space: pre-wrap;
}

// * Headlines
@mixin font-headline-1 {
  @include headline-base;

  font-size: 2rem;
}

@mixin font-headline-2 {
  @include headline-base;

  font-size: 1.8rem;
}

@mixin font-headline-3 {
  @include headline-base;

  font-size: 1.6rem;
}

@mixin font-headline-4 {
  @include headline-base;

  font-size: 1.4rem;
}

@mixin font-headline-5 {
  @include headline-base;

  font-size: 1.2rem;
}

@mixin font-headline-6 {
  @include headline-base;

  font-size: 1rem;
}

@mixin caption {
  color: var(--color-grey-500);
}
