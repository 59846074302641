@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.container {
	@include layout.paper-bw;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.dataPointInfo {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.ruleMessage {
	color: var(--color-primary-main);
}

.button {
	color: black;

	&:hover {
		color: white;
	}

	&_error {
		background-color: var(--error-light);
		border-color: var(--error-main);

		&:hover {
			background-color: var(--error-dark);
			border-color: var(--error-main);
		}
	}

	&_warning {
		background-color: var(--warning-light);
		border-color: var(--warning-main);

		&:hover {
			background-color: var(--warning-dark);
			border-color: var(--warning-main);
		}
	}

	&_success {
		background-color: var(--success-light);
		border-color: var(--success-main);

		&:hover {
			background-color: var(--success-dark);
			border-color: var(--success-main);
		}
	}
}

.values {
	@include layout.paper-bw;
}

.buttons {
	@include layout.button-wrapper-row;
}

.title {
	@include typography.font-headline-5;
}

.caption {
	@include typography.caption;
}
