.container {
  overflow: hidden;
  padding: 0 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: var(--color-primary-main);
    background-color: var(--color-custom-main-light);
  }
}

.inner {
  display: flex;
  align-items: center;
  height: 30px; // Match the height of the row in react window to align the text vertically at center.
  font-size: .9rem;

  & > svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: .5rem;
    fill: var(--color-primary-main);
  }
}

.inactiveTag {
  margin-left: 1rem;
  padding: .25rem .5rem;

  font-size: .8rem;
  font-weight: bold;
  color: red;
  text-transform: uppercase;

  background-color: var(--status-bad);
  border-radius: var(--border-radius);
}
