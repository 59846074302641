.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 2rem;
  }
}

.pages {
  display: flex;
  justify-content: center;

  & button:not(:first-child) {
    margin-left: .5rem;
  }
}

.select {
  padding: .3rem;

  font-weight: 500;
  line-height: 1.75;

  border: 1px solid var(--color-grey-300);
  border-radius: var(--border-radius);

  &:focus {
    border-color: var(--color-primary-light);
    outline: none;
  }
}

.button {
  width: 3rem;
  padding-right: 0;
  padding-left: 0;

  text-align: center;
  text-transform: uppercase;

  border: 1px solid var(--color-grey-300);

  &:hover {
    background-color: var(--color-grey-200);
  }

  &__active {
    color: var(--color-primary-main);
    border: 1px solid var(--color-primary-light);

    &:hover {
      background-color: var(--color-custom-main-light);
    }
  }
}

.input {
  width: 5rem;
  padding: .5rem;

  font-weight: 500;
  line-height: 1.75;

  border: 1px solid var(--color-grey-300);
  border-radius: var(--border-radius);

  &:focus {
    border-color: var(--color-primary-light);
    outline: none;
  }
}

.total {
  font-weight: bold;
}
