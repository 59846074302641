.wrapper {
  display: flex;
  gap: 1rem;
  justify-content: stretch;
  width: 100%;
}

.preloaderWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
