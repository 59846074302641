.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2rem;
}

.link {
	text-decoration: none;
}

.extraInfo {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
}