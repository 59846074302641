.container {
  display: flex;
  align-items: center;
}

.inner {
  display: flex;
  align-items: center;
  padding-right: .5rem;
}

.link {
  padding-right: .5rem;
  color: var(--color-primary-main);

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}
