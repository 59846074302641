@use "src/shared/styles/shared/layout.module";

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.preloader {
	display:flex;
	justify-content: center;
}

.card {
	@include layout.card;
}

.infoPanel {
	display: flex;
	align-items: end;
	justify-content: space-between;
}