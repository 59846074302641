@use "src/shared/styles/shared/typography.module";

.container {
	font-family: Ubuntu, sans-serif;

	&_dark {
		color: var(--color-common-black)
	}

	&_light {
		color: var(--color-common-white)
	}

	&_size {
		&_1 {
			@include typography.font-headline-1;
		}

		&_2 {
			@include typography.font-headline-2;
		}

		&_3 {
			@include typography.font-headline-3;
		}

		&_4 {
			@include typography.font-headline-4;
		}

		&_5 {
			@include typography.font-headline-5;
		}

		&_6 {
			@include typography.font-headline-6;
		}
	}
}
