@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.container {
	@include layout.card;

	flex: 0 1 auto;
	max-width: 20rem;
}

.menu {
	margin-bottom: 1rem;

	& > div:not(:last-child) {
		margin-bottom: .5rem;
	}
}

.title {
	@include typography.font-headline-5;

	margin-bottom: 1rem;
}

.button {
	width: 100%;
}

.menuItem {
	overflow: hidden;

	padding: .5rem;

	text-overflow: ellipsis;
	white-space: nowrap;

	border-radius: var(--border-radius);

	&:hover {
		cursor: pointer;
		background-color: var(--color-custom-main-pale);
	}

	& > * {
		margin-right: .5rem;
		text-overflow: ellipsis;
	}

	&_active {
		background-color: var(--color-custom-main-pale);
	}
}

.redBG {
	padding: .1rem .25rem;

	font-weight: 500;
	color: white;

	background-color: var(--error-light);
	border-radius: var(--border-radius);
}

.menuSVG {
	cursor: pointer;
	width: 1.5rem;
	height: 1.5rem;
	fill: var(--color-primary-main);
}

.linearProgress {
	margin-bottom: 1rem;
}
