@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-around;

	& > span {
		margin-bottom: 1.2rem;
		color: var(--color-primary-main);
	}

	& > h5 {
		margin-bottom: 1.2rem;
		font-weight: bold;
	}
}

.title {
	@include typography.font-headline-5;
}

.content {
	@include layout.card;

	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
}

.pagination {
	margin-bottom: 3rem;
	padding: 2rem;
	box-shadow: 0 5px 10px 0 var(--color-grey-300);
}

.tableWrapper {
	overflow-x: scroll;
}

.table {
	border-spacing: 0;
	padding: 2rem;

	thead {
		tr {
			th {
				padding: .5rem 2rem .5rem .5rem;

				font-weight: 400;
				color: var(--color-grey-400);
				text-align: start;
				vertical-align: text-top;

				border-bottom: 1px solid var(--color-grey-400);
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 2rem 2rem 2rem .5rem;
				font-weight: 600;
				color: var(--color-primary-dark);
				border-bottom: 1px solid var(--color-grey-400);

				& > svg {
					display: flex;
					align-items: center;

					width: 1.5rem;
					height: 1.5rem;

					fill: var(--color-primary-dark);
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.preloaderWrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 2rem;
}

.iconCell {
	display: flex;
	align-items: center;

	& > svg {
		width: 1.25rem;
		height: 1.25rem;
		margin-right: .5rem;
		fill: var(--color-grey-600);
	}
}
