@use "src/shared/styles/shared/layout.module";

.container {
	cursor: pointer;
	display: flex;
	gap: 1.2rem;
	align-items: center
}

.user {
	display: flex;
	margin-right: 1rem;

	&__name {
		margin-right: .5rem;
		font-size: 1.2rem;
		line-height: 1.4rem;
	}
}

.menu {
	@include layout.card;

	position: fixed;
	top: 6rem;
	right: 1rem;
}

