.container {
  display: flex;
  gap: 1.5rem;
}

.group {
  display: flex;
  flex-direction: column;
}

.valueWrapper {
  display: flex;
  align-items: start;
  margin-bottom: .5rem;
  white-space: nowrap;
}

.dot {
  min-width: .5rem;
  min-height: .5rem;
  margin-top: .3rem;
  margin-right: .5rem;

  border-radius: 50%;
}

.label {
  font-size: .8rem;
  line-height: 1.2;
}
