@use "src/shared/styles/shared/typography.module";

// * Shared
.errorWrapper {
	color: var(--error-main);
}

.fieldWrapper {
	display: flex;
	gap: .5rem;
	align-items: center
}

.filter {
	display: flex;
	gap: 1rem;
	align-items: center;
	padding: 5px 16px;

	& > input {
		width: 100%;
	}
}

.field {
	width: var(--dashboard-field-select-width);
	max-width: var(--dashboard-field-select-width);
}

.dotsSVG {
	width: 1.5rem;
	height: 1.5rem;
	fill: var(--color-grey-500);
}

.caption {
	@include typography.caption;
}
