@use "src/shared/styles/shared/typography.module";

@mixin cell {
	overflow: hidden;
	flex: 1;
	padding: .5rem 1rem;
	border: 1px solid var(--color-grey-300);
}

.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& table {
		border-collapse: collapse;

		& td {
			@include cell;
		}
	}
}

.name {
	@include typography.font-headline-5;
}

.subheaderCell {
	@include cell;

	& > b {
		display: flex;
		align-items: center;
	}
}

.displayName {
	@include typography.caption;
}

.inputCell {
	@include cell;

	background-color: white;

	&:hover {
		cursor: pointer;
		background-color: var(--status-good);
	}

	&_readOnly {
		background-color: var(--color-grey-100);

		&:hover {
			cursor: default;
			background-color: var(--color-grey-100);
		}
	}

	&_active {
		background-color: var(--status-good);
	}
}
