.table {
  width: 100%;
  background-color: white;

  & tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  & th, td {
    margin: 0;
    text-align: start;
    border-bottom: 1px solid var(--color-grey-200);

    :last-child {
      border-right: 0;
    }
  }

  // Table header
  & thead {
    & tr {
      & th {
        padding: 1rem;
        font-size: .8rem;
        font-weight: 300;

        & > span {
          padding-right: .5rem;
        }

        & svg {
          height: .6rem;
          fill: var(--color-grey-600);

          &.invisible {
            fill: var(--color-grey-300);
          }
        }

        &:hover .invisible {
          fill: var(--color-grey-600);
        }

        &.link {
          border-right: none;

          & svg.invisible {
            fill: none;
          }
        }
      }
    }
  }

  // Table content
  & tbody {
    & tr {
      & td {
        padding: 1rem;
      }
    }
  }

  // 0 - 959
  @media (max-width: 959px) {
  overflow-x: hidden;
}
}
