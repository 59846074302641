@use "src/shared/styles/shared/layout.module";

.container {
  position: fixed;
  top: 10rem;

  display: none;

  // left - inline
  // z-index - inline

  align-items: center;
  justify-content: center;

  &_active {
    display: block;
  }
}

.modal {
  @include layout.card;

  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

.close {
  cursor: pointer;

  position: absolute;
  top: -45px;
  right: -45px;

  width: 40px;
  height: 40px;

  background-color: white;
  border-radius: 50%;
  box-shadow: var(--shadow-2);

  & svg {
    position: absolute;
    top: 5px;
    left: 5px;

    width: 30px;
    height: 30px;

    fill: var(--color-primary-main);
  }

  &:hover {
    & svg {
      fill: var(--color-primary-light)
    }
  }
}
