@use 'src/shared/styles/shared/typography.module';

.container {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.caption {
	@include typography.caption;
}