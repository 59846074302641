@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.container {
	display: flex;
	flex-direction: column;
}

.infoWrapper {
	display: flex;
	gap: 1rem;
	justify-content: space-between;

	& > div {
		flex: 1 0;
	}
}

.paper {
	@include layout.paper-color;
}

.title {
	@include typography.font-headline-5;

	margin-bottom: .8rem;
}

.buttonWrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
}
