@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.columnHeader {
	display: flex;
	gap: .5rem;
	align-items: center;
	margin-bottom: 1rem;
}

.title {
	@include typography.font-headline-5;
}

.paperCustom {
	@include layout.paper-bw;

	&_unique {
		background-color: #dcf2ff
	}

	&_new {
		background-color: #fff8dc
	}

	&_conflicts {
		background-color: #ffdcdc
	}

	&_same {
		background-color: #dcffe4
	}
}

.people {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
