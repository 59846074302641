@use "src/shared/styles/shared/layout.module";

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.mode {
	@include layout.card;

	display: flex;
	gap: .5rem;
	align-items: center;
}
