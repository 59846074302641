@use "src/shared/styles/shared/typography.module";
@use "src/shared/styles/shared/layout.module";

.header {
	@include layout.paper-bw;

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.caption {
	@include typography.caption;
}

.card {
	@include layout.card;
}

.targetInfo {
	display: flex;
	align-items: center;

	& > svg {
		margin-right: .7rem;
	}
}

.companyInfoRow {
	display: flex;

	& > div:first-child {
		width: 4rem;
	}

	&__value {
		@include typography.font-headline-5;
	}
}
