@use "src/shared/styles/shared/typography.module";

.container {
	display: flex;
	flex-direction: column;
}

.title {
	@include typography.font-headline-5;

	margin-bottom: 1.4rem;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.fieldWrapper {
	display: flex;
	gap: 1rem;
}
