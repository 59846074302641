@use "src/shared/styles/shared/typography.module";

$row-height: 2.2rem;
$column-gap: .5rem;

.container {
	display: flex;
	gap: 2rem;
	margin-bottom: 1rem;
}

.column {
	display: flex;
	flex-direction: column;
	gap: $column-gap;

	& > div {
		height: $row-height;
	}
}

.companyInfo {
	display: flex;
	align-items: center;
	height: $row-height;
	margin-bottom: $column-gap;

	&__caption {
		@include typography.caption;

		&_conflicts {
			width: 10.5rem;
		}

		&_source {
			width: 4.5rem;
		}
	}
}

.tooltip {
	display: flex;
	align-items: center;
	height: $row-height;
}