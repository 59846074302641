@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.header {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.titleWrapper {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.title {
	@include typography.font-headline-5;
}

.card {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.error {
	color: var(--error-main);
}

.buttons {
	@include layout.button-wrapper-row;

	align-items: end;
}
