@use "src/shared/styles/shared/layout.module";

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}


.inner {
	@include layout.paper-color;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 2rem;

	box-shadow: var(--shadow-3);
}

.logo {
	& > svg {
		display: flex;
		justify-content: center;

		width: 100%;
		height: 3rem;
		margin-bottom: 2rem;}
}
