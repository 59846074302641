$card-padding: 1.5rem;
$close-icon-size: 1rem;

.container {
	pointer-events: none;

	position: fixed;
	z-index: -1;
	inset: 0;

	opacity: 0;
}

.overlay {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	background: var(--color-grey-a400);
}

.content {
	position: relative;
	transform: scale(.5);

	flex: 1;

	padding: calc($card-padding + $close-icon-size) $card-padding $card-padding $card-padding;

	background-color: white;
	border-radius: 1rem;
	box-shadow: var(--shadow-1);

	transition: .3s transform;
}

.opened {
	pointer-events: auto;
	z-index: var(--z-index-modal);
	opacity: 1;

	& .content {
		transform: scale(1);
	}
}

.isClosing {
	& .content {
		transform: scale(.2);
	}
}

.close {
	cursor: pointer;

	position: absolute;
	top: $close-icon-size;
	right: $close-icon-size;

	width: $close-icon-size;
	height: $close-icon-size;

	& svg {
		width: $close-icon-size;
		height: $close-icon-size;
		fill: var(--color-primary-main);
	}

	&:hover {
		& > svg {
			fill: var(--color-primary-light);
		}
	}
}
