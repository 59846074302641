.container {
  position: relative;
  width: 75px;
  perspective: 300px;
}

@keyframes animate {
  50% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(180deg);
  }
}

.preloader {
  position: absolute;
  inset: 0;

  width: 60px;
  height: 40px;
  margin: auto;

  background: var(--color-primary-main);
  border-radius: 4px;

  animation: animate 1.5s infinite;
}
