.fieldWrapper {
	display: flex;
	align-items: center;
}

.textField {
	width: var(--dashboard-field-width);

	&_isName {
		& > * {
			font-weight: bold;
		}
	}
}

.dots {
	width: 1rem;
	height: 1rem;
	fill: var(--color-grey-500);
}

.additionalTextRight {
	margin-left: .5rem;
}

.error {
	color: var(--error-main);
}
