// * Cards
@mixin paper {
  padding: 1rem;
  background-color: white;
  border-radius: var(--border-radius);
}

@mixin paper-bw {
  @include paper;

  border: 1px solid var(--color-grey-200);
}

@mixin paper-color {
  @include paper;

  border: 1px solid var(--color-custom-main-border);
}

@mixin card {
  padding: .75rem;
  background: white;
  border-radius:var(--border-radius);
  box-shadow: var(--shadow-1);
}

// * Wrappers
@mixin preloader-wrapper {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
}

@mixin button-wrapper-column {
  display: flex;
  flex-direction: column;

  & div:not(:last-child), button:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@mixin button-wrapper-row {
  display: flex;
  gap: 1rem;
}
