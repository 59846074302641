.container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.filter {
  display: flex;
  gap: 1rem;
  align-items: center;
}
