@use "src/shared/styles/shared/layout.module";

.container {
	@include layout.card;

	position: relative;

	display: flex;

	height: 15rem;

	color: white;

	background-image: url('src/shared/assets/img/qcDashboardCard.webp');
	background-size: cover;
	box-shadow: var(--shadow-2);

	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-10);
		transition: box-shadow 0.2s ease;
	}

	.title {
		position: absolute;
		top: 0;
		left: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 15rem;
		padding: 0 1rem;    

		text-align: center;
	}
}