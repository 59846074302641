.container {
  display: flex;
  margin-bottom: 2rem;

  & > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
