@use "src/shared/styles/shared/typography.module";

.title {
	@include typography.font-headline-5;

	margin-bottom: 1.2rem;
}

.paragraph {
	margin-bottom: 1.2rem;
	white-space: pre;
}

.link {
	display: block;
	width: fit-content;
}

.preloader {
	& svg {
		height: 1rem;
	}
}
