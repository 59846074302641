.header {
  position: sticky;
  z-index: var(--z-index-header);
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: var(--header-height);
  padding: 0 2rem;

  background-color: white;
  box-shadow: var(--shadow-2);

  &__left {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 4rem;
    }
  }
}

.logo {
  & img {
    width: 10rem;
    object-fit: contain;
  }

  & :hover {
    cursor: pointer;
  }
}

.companyId {
  margin-right: 1rem;
  color: var(--color-primary-main);

  &__id {
    font-weight: 600;
  }
}

.silverLogo {
  height: 2rem;
}
