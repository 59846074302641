@use "src/shared/styles/shared/layout.module";

.container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 0 10rem;

	.card {
		@include layout.card;

		display: grid;
		gap: 1rem;	

		.header {
			display: flex;
			justify-content: space-between;

			.headerButtons {
				display: flex;
				gap: 1rem;
			}
		}

		.searchBarRow {
			display: flex;
			gap: .5rem;
			align-items: center;
			justify-content: stretch;

			& > div:first-child {
				flex: 1;
			}
		}
	}
}