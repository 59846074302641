@use "src/shared/styles/shared/typography.module";

@mixin cell {
  overflow: hidden;
  flex: 1;
  padding: .5rem 1rem;
  border: 1px solid var(--color-grey-300);
}

.table {
  border-collapse: collapse;

  & td {
    @include cell;
  }
}

.subheaderCell {
  @include cell;

  & > b {
    display: flex;
    align-items: center;
  }
}

.displayName {
  @include typography.caption;
}

.dataCell {
  @include cell;

  background-color: white;

  &_active {
    background-color: var(--status-good)
  }
}
