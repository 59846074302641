$gap-row: .5rem;

@mixin row {
	gap: $gap-row;
	align-items: center;
}

@mixin column {
	align-items: start;
}

.container {
	display: flex;

	&_start {
		@include row;
	}

	&_end {
		@include row;

		flex-direction: row-reverse;
	}

	&_top {
		@include column;

		flex-direction: column;
	}

	&_bottom {
		@include column;

		flex-direction: column-reverse;
	}
}