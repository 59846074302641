@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.filterList {
	@include layout.paper-color;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1.5rem;
}

.pagination {
	margin-bottom: 1rem;
}

.companyList {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.card {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.person {
	@include layout.paper-color;
}

.property {
	@include layout.paper-bw;
}

.infoRow {
	display: flex;
	gap: 4rem;
	align-items: start;
	margin-bottom: 1rem;
}

.infoColumn {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& > button {
		width: fit-content;
	}
}

.caption {
	@include typography.caption;
}

.displayName {
	@include typography.font-headline-5;
}
