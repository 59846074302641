@use "src/shared/styles/shared/layout.module";

.container {
  overflow: hidden;
  padding: 0 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: var(--color-primary-main);
    background-color: var(--color-custom-main-light);
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40px; // Match the height of the row in react window to align the text vertically at center.

  font-size: .9rem;
}

.buttons {
  @include layout.button-wrapper-row;
}
