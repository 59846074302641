@use "src/shared/styles/shared/layout.module";

.container {
	margin-bottom: 2rem;
}

.nav {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;

	&_right {
		@include layout.button-wrapper-row;
	}
}
