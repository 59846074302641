.container {
	position: relative;
}

.searchResults {
	position: absolute;
	z-index: 100;
	left: 0;

	width: 100%;
	height: fit-content;

	background-color: white;
	box-shadow: var(--shadow-2);
}
