@use "src/shared/styles/shared/layout.module";

.container {
	@include layout.card;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.inner {
	@include layout.paper-color;
}

.switchButton {
	& span > svg {
		width: 1.2rem;
		height: 1.2rem;
		margin-right: .5rem;
		fill: var(--color-primary-main);
	}
}

.buttons {
	@include layout.button-wrapper-row;
}
