@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.dataPoint {
	@include layout.paper-color;

	&__wrapper {
		display: flex;
		align-items: center;

		& > div:first-child {
			padding-right: .5rem;
		}
	}
}

.caption {
	@include typography.caption;
}
