@use "src/shared/styles/shared/typography.module";

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.propertyList {
	overflow-x: hidden;
	height: 60vh;
	margin-bottom: 1rem;
}

.group {
	flex: 0;
	margin-bottom: 1rem;
	padding: .5rem;
	border: 1px solid var(--color-custom-main-border);

	&__name {
		@include typography.font-headline-4;
	}
}

.property {
	margin-bottom: .75rem;
}

.displayName {
	padding: 0 1rem;
	color: var(--color-primary-light);
}

.move {
	color: var(--error-dark);
}

.value {
	margin-bottom: .5rem;
	padding-left: 2rem;
	white-space: pre-wrap;
}
