@use "src/shared/styles/shared/layout.module";

.container {
	flex: 1 1 100%;

	& > * {
		height: 100%;
	}
}

.card {
	@include layout.card;
}
