@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

@mixin cell {
	overflow: hidden;
	flex: 1;
	padding: .5rem 1rem;
	overflow-wrap: anywhere;
}

.container {
	@include layout.card;

	display: flex;
	flex: 1 1;
	flex-direction: column;
	gap: 1rem;

	height: 100%;
}

.title {
	@include typography.font-headline-5;
}

.caption {
	@include typography.caption;
}

.table {
	overflow-x: hidden;
	margin-bottom: 1rem;
	border: 1px solid var(--color-grey-600);
}

.header {
	position: sticky;
	top: 0;
}

.cellWrapper {
	display: flex;
}

.cellBorders {
	display: flex;
	flex: 1;

	& > div {
		&:first-child {
			border-left: 1px solid var(--color-grey-600);
		}

		&:last-child {
			border-right: 1px solid var(--color-grey-600);
		}
	}
}

.tabWrapper {
	display: flex;

	& > div {
		border-right: 1px solid var(--color-grey-600);
		border-left: 1px solid var(--color-grey-600);
	}
}

.headerCell {
	@include cell;

	color: white;
	background-color: var(--color-grey-500);
	border: 1px solid var(--color-grey-600);
}

.tab {
	@include cell;

	display: flex;
	background-color: var(--color-grey-300);

	& > button:not(:last-child) {
		margin-right: 2rem;
	}
}

.button {
	flex: 1;
}

.cell {
	@include cell;

	border-right: 1px solid var(--color-grey-200);
	border-bottom: 1px solid var(--color-grey-200);
	border-left: 1px solid var(--color-grey-200);

	&_variable {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&_readOnly {
		color: red;
	}
}

.cellInner {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.value {
	white-space: pre-wrap;
}

.inputCell {
	@include cell;

	background-color: white;
	border-right: 1px solid var(--color-grey-200);
	border-bottom: 1px solid var(--color-grey-200);
	border-left: 1px solid var(--color-grey-200);

	&:hover {
		cursor: pointer;
		background-color: var(--status-good);
	}

	&_readOnly {
		background-color: var(--color-grey-100);

		&:hover {
			cursor: default;
			background-color: var(--color-grey-100);
		}
	}

	&_allowsMany {
		background-color: #fff2c0;

		&:hover {
			background-color: #fff2c0;
		}
	}
	
	&_selected {
		background-color: var(--status-good);
	}
}
