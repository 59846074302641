$cell-width: 14rem;

.container {
	overflow-x: scroll;

	& table {
		overflow-x: scroll;
		table-layout: fixed;
		border-spacing: 0;
		width: 100%;

		& thead {
			position: sticky;
			z-index: 1;
			top: 0;

			& th {
				width: $cell-width;
				padding: .5rem 2rem .5rem .5rem;

				font-weight: normal;
				color: var(--color-grey-500);
				text-align: start;
				vertical-align: text-top;

				background-color: white;
				border-bottom: 1px solid var(--color-grey-400);

				& .arrow {
					height: .4rem;
					padding-left: .5rem;
					fill: var(--color-primary-dark);
				}

				& > div {
					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		& tbody {
			position: relative;
			z-index: 0;

			& tr {
				& td {
					width: $cell-width;
					padding: .5rem 2rem .5rem .5rem;

					font-weight: 600;
					color: var(--color-primary-dark);

					background-color: white;
					border-bottom: 1px solid var(--color-grey-400);
				}

				&.clickable {
					&:hover {
						cursor: pointer;
		
						& td {
							background-color: var(--color-custom-main-pale);
						}
					}
				}
			}
		}
	}
}
