.container {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-around;

	label {
		white-space: nowrap;;
	}

	div {
		width: 100%;
	}
}