.container {
  position: relative;
}

.button {
  width: 17rem;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 600;

  &:disabled {
    color: white;
    background-color: var(--color-custom-main-border)
  }
}

.searchResults {
  position: absolute;
  z-index: 100;
  left: 0;

  width: 100%;
  height: fit-content;
  padding-bottom: 1.2rem;

  background-color: white;
  box-shadow: var(--shadow-2);
}

.fixedSizeList {
  margin-bottom: 1rem;
}
