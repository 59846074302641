@use "src/shared/styles/shared/layout.module";
@use "src/shared/styles/shared/typography.module";

.preloader {
	position: fixed;
	z-index: var(--z-index-notification);
	right: 1.5rem;
	bottom: 3rem;
}

.container {
	display: flex;
	gap: 1rem;

	& > div {
		flex: 1;
	}
}

.card {
	@include layout.card;
}

.title {
	@include typography.font-headline-4;

	margin-bottom: 1rem;
}

.filters {
	@include layout.paper-color;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1.5rem;
}

.pagination {
	margin-bottom: 1rem;
}

.companiesList {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	height: calc(100% - 22rem);
}

.list {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	height: calc(100% - 3rem);
}

.personList {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.companyOutlierItem {
	@include layout.paper-bw;

	display: flex;
	gap: 1rem;
	align-items: center;

	&:hover {
		cursor: pointer;
		background-color: var(--color-custom-main-pale);
	}

	&:active {
		background-color: var(--color-custom-main-light);
	}

	&_active {
		background-color: var(--color-custom-main-light);
		border-color: var(--color-primary-main)
	}
}

.status {
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
}

.dataPointInfo {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.id {
	@include typography.caption;
}

.name {
	@include typography.font-headline-5;
}
