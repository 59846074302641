.container {
  width: 1.5rem;
  height: 1.5rem;

  & svg {
    width: inherit;
    height: inherit;
  }

  &_default {
    & svg {
      fill: var(--color-grey-700);

      &:hover {
        fill: var(--color-primary-main)
      }
    }
  }

  &_error {
    & svg {
      fill: var(--error-main);

      &:hover {
        fill: var(--error-dark);
      }
    }
  }
}
